<template>
  <div class="button-group-input">
    <input
      type="text"
      v-model="filename"
      placeholder="Selecione um arquivo..."
      class="w-full sm:w-7/12 mb-2 float-left"
      disabled
    />

    <label
      :class="['button button-input text-white', buttonBackground]"
      @change="change"
    >
      <input
        type="file"
        :accept="accept"
        style="display: none"
        ref="inputfile"
      />
      {{ buttonText }}
    </label>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FileInput",
  props: {
    index: {
      type: Number,
      required: false,
    },
    id: {
      type: Number,
      required: false,
    },
    accept: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filename: "",
      buttonBackground: "bg-lime-600",
      buttonText: "Selecionar",
    };
  },
  methods: {
    handlingFilename(file) {
      if (typeof file === "string") {
        return file.replace(/\\/g, "/").replace(/.*\//, "");
      } else {
        if (!file) return "";
        if (file.files.length > 1) {
          return file.files.length + " arquivos";
        } else {
          return file.value.replace(/\\/g, "/").replace(/.*\//, "");
        }
      }
    },
    change() {
      this.filename = this.handlingFilename(this.$refs.inputfile);
      this.buttonBackground = this.filename ? "bg-yellow-500" : "bg-lime-600";
      this.buttonText = this.filename ? "Alterar" : "Selecionar";

      this.$emit("change", this.$refs.inputfile, this.index, this.id);
    },
  },
});
</script>
