<template>
  <Layout>
    <form @submit.prevent="submit">
      <div v-if="subscription.responsavel" class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="form-heading border-none">
            <h6 class="text-left">Representante da chapa</h6>
            <p class="text-sm">
              Campos marcados com (<span class="text-red-700">*</span>) são de
              preenchimento obrigatório.
            </p>
          </div>
        </div>

        <div class="section-content md:col-span-2">
          <div class="form">
            <div class="body">
              <div
                :class="{ group: true, groupError: $v.responsible.cpf.$error }"
              >
                <label class="label">
                  CPF <span class="text-red-700">*</span></label
                >

                <input
                  type="text" autocomplete="off"
                  class="w-full sm:w-4/12"
                  placeholder="Apenas números"
                  v-model.trim="$v.responsible.cpf.$model"
                  disabled
                />
              </div>

              <div
                :class="{
                  group: true,
                  groupError: $v.responsible.fullname.$error,
                }"
              >
                <label class="label" for="nomeCompletoResponsavel"
                  >Nome completo <span class="text-red-700">*</span></label
                >

                <input
                  id="nomeCompletoResponsavel"
                  type="text" :autocomplete="autoRandom()"
                  class="w-full sm:w-7/12"
                  placeholder="Nome completo"
                  v-model.trim="$v.responsible.fullname.$model"
                />

                <p
                  class="messageError"
                  v-if="!$v.responsible.fullname.required"
                >
                  Nome completo deve ser preenchido.
                </p>

                <p
                  class="messageError"
                  v-if="!$v.responsible.fullname.minLength"
                >
                  Nome completo dever ter pelo menos
                  {{ $v.responsible.fullname.$params.minLength.min }} letras.
                </p>
              </div>

              <div
                :class="{
                  group: true,
                  groupError: $v.responsible.email.$error,
                }"
              >
                <label class="label" for="emailResponsavel">
                  E-mail (Login) <span class="text-red-700">*</span></label
                >

                <input
                  disabled
                  id="emailResponsavel"
                  type="text" :autocomplete="autoRandom()"
                  class="w-full sm:w-6/12"
                  placeholder="E-mail"
                  v-model.trim="$v.responsible.email.$model"
                />

                <p class="messageError" v-if="!$v.responsible.email.required">
                  E-mail deve ser preenchido.
                </p>

                <p class="messageError" v-if="!$v.responsible.email.email">
                  E-mail informado deve ser válido.
                </p>
              </div>

              <hr class="mt-6 mb-8" />

              <div v-if="candidates.length > 1">
                <div v-if="false" class="group">
                  <label class="label">
                    Logomarca da chapa

                    <a
                      v-if="responsible.logo.url"
                      class="
                        button button-link
                        text-white
                        hover:text-gray-50
                        bg-cyan-600
                        hover:bg-cyan-500
                      "
                      :href="responsible.logo.url"
                      target="_blank"
                    >
                      Visualizar
                    </a>
                  </label>

                  <div class="w-full sm:w-10/12 lg:w-8/12">
                    <file-input
                      :index="0"
                      :id="0"
                      accept="image/png, image/jpg, image/jpeg, application/pdf"
                      @change="fileChangeLogo"
                    ></file-input>
                  </div>
                </div>

                <div class="group">
                  <label class="label" for="nomeChapa">
                    Nome da chapa <span class="text-red-700">*</span>
                  </label>

                  <input
                    id="nomeChapa"
                    type="text" :autocomplete="autoRandom()"
                    class="w-full sm:w-8/12"
                    placeholder="Nome da chapa"
                    v-model="responsible.plateName"
                  />
                </div>
              </div>

              <div v-if="false" class="group">
                <label class="label">
                  Carta programa (falta {{ cartaProgramaCharactersLeft }})<span
                    class="text-red-700"
                    >*</span
                  >
                </label>

                <textarea
                  class="w-full sm:w-8/12"
                  :placeholder="
                    'Máximo de ' + CARTA_PROGRAMA_MAXLENGTH + ' caracteres...'
                  "
                  :maxlength="CARTA_PROGRAMA_MAXLENGTH"
                  rows="4"
                  v-model="responsible.letter"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>

      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="form-heading border-none">
            <h6 class="text-left">Candidato(s)</h6>
            <p class="text-sm">
              Campos marcados com (<span class="text-red-700">*</span>) são de
              preenchimento obrigatório.
            </p>
          </div>
          <div v-if="LGPD_MESSAGE" v-html="LGPD_MESSAGE">

          </div>
        </div>

        <div class="section-content md:col-span-2">
          <div v-for="(candidate, index) in candidates" :key="index">
            <div class="form">
              <div class="form-heading mb-6 flex justify-between">
                <h5>{{ candidate.role }}</h5>

                <div class="flex">
                  <Button
                    color="white"
                    background="lime-600"
                    :icon="true"
                    @action="hideForm(index)"
                  >
                    <span v-show="candidate.visible">
                      <i class="fa fa-minus"></i>
                    </span>
                    <span v-show="!candidate.visible">
                      <i class="fa fa-chevron-down"></i>
                    </span>
                  </Button>
                </div>
              </div>

              <transition name="fade-in-down">
                <div v-if="candidate.visible">
                  <div class="body">
                    <div class="group" v-if="fieldsShow['fullname']">
                      <label class="label" v-html="fieldsLabel['fullname']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-8/12"
                        :placeholder="fieldsPlaceholder['fullname']"
                        v-model="candidate.fullname"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['birthdate']">
                      <label class="label" v-html="fieldsLabel['birthdate']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['birthdate']"
                        v-model="candidate.birthdate"
                        v-mask="['##/##/####']"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['nickname']">
                      <label class="label" v-html="fieldsLabel['nickname']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['nickname']"
                        v-model="candidate.nickname"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['cpf']">
                      <label class="label" v-html="fieldsLabel['cpf']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-5/12"
                        :placeholder="fieldsPlaceholder['cpf']"
                        maxlength="20"
                        :disabled="fieldsDisabled['cpf']"
                        v-model="candidate.cpf"
                      />

                      <p
                        class="
                          w-full
                          sm:w-6/12
                          bg-yellow-200
                          py-1
                          px-2
                          text-xs
                          rounded
                          mt-2
                        "
                      >
                        Inserir apenas números (Caso contrário a inscrição não
                        será concluída)
                      </p>
                    </div>

                    <div class="group" v-if="fieldsShow['registration']">
                      <label class="label" v-html="fieldsLabel['registration']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-4/12"
                        :placeholder="fieldsPlaceholder['registration']"
                        v-model="candidate.registration"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['rg']">
                      <label class="label" v-html="fieldsLabel['rg']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-4/12"
                        :placeholder="fieldsPlaceholder['rg']"
                        v-model="candidate.rg"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['maritalStatus']">
                      <label class="label" v-html="fieldsLabel['maritalStatus']">

                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-4/12"
                        :placeholder="fieldsPlaceholder['maritalStatus']"
                        v-model="candidate.maritalStatus"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['email']">
                      <label class="label" v-html="fieldsLabel['email']">
                       </label>

                      <input
                        type="email" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['email']"
                        v-model="candidate.email"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['phone']">
                      <label class="label" v-html="fieldsLabel['phone']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-5/12"
                        :placeholder="fieldsPlaceholder['phone']"
                        v-model="candidate.phone"
                        v-mask="['(##) ####-####']"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['cellphone']">
                      <label class="label" v-html="fieldsLabel['cellphone']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-5/12"
                        :placeholder="fieldsPlaceholder['cellphone']"
                        v-model="candidate.cellphone"
                        v-mask="['(##) #####-####']"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['zipcode']">
                      <label class="label" v-html="fieldsLabel['zipcode']">
                       </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-4/12"
                        :placeholder="fieldsPlaceholder['zipcode']"
                        v-model="candidate.zipcode"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['address']">
                      <label class="label" v-html="fieldsLabel['address']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['address']"
                        v-model="candidate.address"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['district']">
                      <label class="label" v-html="fieldsLabel['district']">
                        </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-4/12"
                        :placeholder="fieldsPlaceholder['district']"
                        v-model="candidate.district"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['city']">
                      <label class="label" v-html="fieldsLabel['city']">
                        </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-4/12"
                        :placeholder="fieldsPlaceholder['city']"
                        v-model="candidate.city"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['state']">
                      <label class="label" v-html="fieldsLabel['state']">
                        </label>
                      <select
                        class="w-full sm:w-5/12"
                        :disabled="fieldsDisabled['state']"
                        v-model="candidate.state"
                      >
                        <option :value="null" selected>
                          {{fieldsPlaceholder['state']}}
                        </option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AM">AM</option>
                        <option value="AP">AP</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MG">MG</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="PR">PR</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="RS">RS</option>
                        <option value="SC">SC</option>
                        <option value="SE">SE</option>
                        <option value="SP">SP</option>
                        <option value="TO">TO</option>
                      </select>
                    </div>

                    <div class="group" v-if="fieldsShow['profession']">
                      <label class="label" v-html="fieldsLabel['profession']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['profession']"
                        v-model="candidate.profession"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['planAssociate']">
                      <label class="label" v-html="fieldsLabel['planAssociate']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['planAssociate']"
                        v-model="candidate.planAssociate"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['subscriptionNumber']">
                      <label class="label" v-html="fieldsLabel['subscriptionNumber']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['subscriptionNumber']"
                        v-model="candidate.subscriptionNumber"
                      />
                    </div>

                    <div class="group" v-if="fieldsShow['pensionSituation']">
                      <label class="label" v-html="fieldsLabel['pensionSituation']">
                      </label>
                      <select
                        class="w-full sm:w-5/12"
                        v-model="candidate.pensionSituation"
                      >
                        <option :value="null" selected>
                          {{fieldsPlaceholder['pensionSituation']}}
                        </option>
                        <option
                          v-for="(value, index) in typesPensionSituation"
                          :key="index"
                          :value="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                    </div>

                    <div class="group" v-if="fieldsShow['subscriptionDate']">
                      <label class="label" v-html="fieldsLabel['subscriptionDate']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['subscriptionDate']"
                        v-model="candidate.subscriptionDate"
                        v-mask="['##/##/####']"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['admissiondate']">
                      <label class="label" v-html="fieldsLabel['admissiondate']">
                      </label>

                      <input
                        type="text" :autocomplete="autoRandom()"
                        class="w-full sm:w-6/12"
                        :placeholder="fieldsPlaceholder['admissiondate']"
                        v-model="candidate.admissiondate"
                        v-mask="['##/##/####']"
                      />
                    </div>
                    <div class="group" v-if="fieldsShow['curriculum']">
                      <label class="label" v-html="fieldsLabel['curriculum']">

                      </label>

                      <textarea
                        class="w-full sm:w-8/12"
                        :placeholder="fieldsPlaceholder['curriculum']"
                        :maxlength="CURRICULO_RESUMIDO_MAXLENGTH"
                        rows="6"
                        v-model="candidate.curriculum"
                      ></textarea>
                      <span>(falta
                        {{ curriculoResumidoCharactersLeft(index) }})</span>
                    </div>

                    <hr class="mt-6 mb-8" />
                    <h6 class="text-left">Anexos</h6>
                    <p class="text-xs">OBS: É possível importar apenas um arquivo por campo. Caso necessário,
                      unifique os documentos com antecedência.</p>
                    <p class="pb-5 text-xs">Ex: As 5 certidões negativas devem estar em um único arquivo de PDF.
                      Extensões aceitas: PDF, JPEG, PNG e ZIP.</p>
                    <div
                      v-for="(data, fileIndex) in candidate.files"
                      :key="fileIndex"
                    >
                      <div class="group">
                        <label class="label">
                          <span v-html="data.texto"></span>

                          <a
                            v-if="data.url"
                            class="
                              button button-link
                              text-white
                              hover:text-gray-50
                              bg-cyan-600
                              hover:bg-cyan-500
                            "
                            :href="data.url"
                            target="_blank"
                          >
                            Visualizar
                          </a>
                        </label>

                        <div class="w-full sm:w-10/12 lg:w-8/12">
                          <file-input
                            :index="fileIndex"
                            :id="candidate.id"
                            :accept="data.accept"
                            @change="fileChange"
                          ></file-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <div class="form-footer">
            <Button
              color="white"
              background="yellow-500"
              :group="true"
              @action="cancel"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              color="white"
              background="lime-600"
              :group="true"
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { required, minLength, numeric, email } from "vuelidate/lib/validators";
import Layout from "@/components/layout/subscription/Layout.vue";
import FileInput from "../../components/elements/FileInput.vue";
import Button from "../../components/elements/Button.vue";
import services from "@/services";
import { mask}  from 'vue-the-mask'
import { v4 } from 'uuid'

export default Vue.extend({
  name: "SubscriptionChange",
  directives: {mask},
  data() {
    return {
      typesPensionSituation: [],
      subscription: {},
      LGPD_MESSAGE: '',
      CARTA_PROGRAMA_MAXLENGTH: 600,
      CURRICULO_RESUMIDO_MAXLENGTH: 600,
      responsible: {},
      candidates: [],
      fieldsRequired: {},
      fieldsShow: {},
      fieldsLabel: {},
      fieldsDisabled: {},
      fieldsPlaceholder: {}
    };
  },
  components: {
    Layout,
    FileInput,
    Button,
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamSubscription", "getParamHotsite"]),
    ...mapGetters("subscription", [
      "getResponsible",
      "getCandidates",
      "getToken",
    ]),
    cartaProgramaCharactersLeft() {
      return this.responsible && this.responsible.letter
        ? this.CARTA_PROGRAMA_MAXLENGTH - this.responsible.letter.length
        : this.CARTA_PROGRAMA_MAXLENGTH;
    },
  },
  validations () {
    let responsible = {}
    responsible['fullname'] = {
      required,
      minLength: minLength(4),
    }
    responsible['cpf'] = {
      required,
      numeric,
    }
    responsible['email'] = {
      required,
      email,
    }
    return {responsible}
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    ...mapActions("subscription", ["cleanDataSubscription"]),
    autoRandom() {
        return v4()
    },
    curriculoResumidoCharactersLeft(index) {
      let candidate = this.candidates[index] || {};
      return candidate && candidate.curriculum
        ? this.CURRICULO_RESUMIDO_MAXLENGTH - candidate.curriculum.length
        : this.CURRICULO_RESUMIDO_MAXLENGTH;
    },
    hideForm(index) {
      this.candidates[index].visible
        ? (this.candidates[index].visible = false)
        : (this.candidates[index].visible = true);

      this.$forceUpdate();
    },
    EmptyOrNull(value) {
      return value !== null && value !== "";
    },
    verifyCandidates() {
      let pendencies = [];
      let cpfVerified = [];
      let emailVerified = [];

      this.candidates.forEach((oneCandidate) => {
        this.candidates.forEach((twoCandidate) => {
          if (
            this.EmptyOrNull(oneCandidate.cpf) &&
            this.EmptyOrNull(twoCandidate.cpf)
          ) {
            if (
              oneCandidate.id !== twoCandidate.id &&
              oneCandidate.cpf === twoCandidate.cpf &&
              !(
                cpfVerified.includes(oneCandidate.id) ||
                cpfVerified.includes(twoCandidate.id)
              )
            ) {
              cpfVerified.push(oneCandidate.id);
              pendencies.push(
                oneCandidate.cpf + " foi informado mais de uma vez."
              );
            }
          }
          if (
            this.EmptyOrNull(oneCandidate.email) &&
            this.EmptyOrNull(twoCandidate.email)
          ) {
            if (
              oneCandidate.id !== twoCandidate.id &&
              oneCandidate.email === twoCandidate.email &&
              !(
                cpfVerified.includes(oneCandidate.id) ||
                cpfVerified.includes(twoCandidate.id)
              )
            ) {
              pendencies.push(
                "O e-mail " +
                  oneCandidate.email +
                  " foi informado mais de uma vez."
              );
            }
          }
        });

        cpfVerified.push(oneCandidate.id);
        emailVerified.push(oneCandidate.id);
      });
      return pendencies;
    },
    fileChangeLogo(input) {
      this.responsible.logo.file = input.files[0];
    },
    fileChange(input, index, id) {
      if (id) {
        let candidate = this.candidates.find(
          (candidate) => candidate.id === id
        );
        candidate.files[index].file = input.files[0];
      }
    },
    async sendFilesResponsible(token) {
      let updatedFiles = [];
      let logo = this.responsible.logo;

      if (logo.file) {
        let fileName = "foto-chapa";

        if (logo.file && typeof logo.file === "object") {
          let updatedFile = services.Subscription.uploadFile(
            0,
            logo.file,
            fileName,
            token
          ).then((response) => {
            this.responsible.logo.url = response.Location;
            return response.Location;
          });

          updatedFiles.push(updatedFile);
        }
      } else if (logo.url) {
        this.responsible.logo.url = logo.url;
      }

      return Promise.all(updatedFiles);
    },
    async sendFilesCandidates(token) {
      let updatedFiles = [];

      this.candidates.forEach((candidate) => {
        candidate.files.forEach(async (data, index) => {
          let file = data.file;

          if (file) {
            let arquivo = data.arquivo;

            if (file && typeof file === "object") {
              let updatedFile = services.Subscription.uploadFile(
                index,
                file,
                arquivo,
                token
              ).then((response) => {
                candidate.files[index].url = response.Location;
                return response.Location;
              });

              updatedFiles.push(updatedFile);
            }
          } else if (data.url) {
            candidate.files[index].url = data.url;
          }
        });
      });

      return Promise.all(updatedFiles);
    },
    submit() {
      if (!this.subscription.responsavel) {
        this.responsible.cpf = this.candidates[0].cpf
        this.responsible.email = this.candidates[0].email
        this.responsible.fullname = this.candidates[0].fullname
      }
      this.doSubmit()
    },
    cancel() {
      this.$router.push({ name: "SubscriptionSituation" });
    },
    async doSubmit() {

      this.changeLoading(true);
      const token = this.getToken.token;

      let verifyCandidates = this.verifyCandidates();

      if (verifyCandidates.length > 0) {
        this.changeLoading(false);

        for (let i = 0; i < verifyCandidates.length; i++) {
          this.sendNotification({
            type: "error",
            message: verifyCandidates[i],
          });
        }
        return;
      }

      await this.sendFilesResponsible(token);
      await this.sendFilesCandidates(token);

      const updateData = {
        responsible: {
          fullname: this.responsible.fullname,
          cpf: this.responsible.cpf,
          email: this.responsible.email,
          plateName: this.responsible.plateName,
          letter: this.responsible.letter,
          logo: this.responsible.logo.url,
        },
        candidates: this.candidates,
        subscription: this.subscription
      };
      await services.Subscription.update(token, updateData).then(
        (response) => {
          this.changeLoading(false);
          if (response.data.status === "falhou") {
            let message = response.data.message;
            if (Array.isArray(message)) {
              for (let i = 0; i < message.length; i++) {
                this.sendNotification({
                  type: "error",
                  message: message[i],
                });
              }
            } else {
              this.sendNotification({
                type: "error",
                message: message,
              });
            }
          } else {
            this.sendNotification({
              type: "success",
              message: response.data.message,
            });

            this.$router.push({ name: "SubscriptionSituation" });
          }
        }
      );
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.responsible = this.getResponsible;
    this.candidates = this.getCandidates;
    if (this.getParamHotsite.pages.subscription) {
      let page = this.getParamHotsite.pages.subscription
      if ( page.curriculoResumidoMaxlength ) {
        this.CURRICULO_RESUMIDO_MAXLENGTH = page.curriculoResumidoMaxlength
      }
      if (page.LGPD_MESSAGE) {
        this.LGPD_MESSAGE = page.LGPD_MESSAGE
      }
    }

    let filesRequired = [];
    let subscriptions = this.getParamSubscription.filter( (subscription) => {
      return subscription.nome === this.responsible.type.name
    })
    let subscriptionFromResponsible = subscriptions[0]
    filesRequired = subscriptionFromResponsible.files;
    let fields = subscriptionFromResponsible.fields

    if (subscriptionFromResponsible.situacoes && subscriptionFromResponsible.situacoes.length > 0 ) {
      this.typesPensionSituation = subscriptionFromResponsible.situacoes
    } else {
      this.typesPensionSituation = []
    }


    let fieldsLabel = {}
    let fieldsPlaceholder = {}

    fieldsLabel['fullname'] = 'Nome completo <span class="text-red-700">*</span>'
    fieldsLabel['nickname'] = 'Nome social (opcional)'
    fieldsLabel['cpf'] = 'CPF ou Nº do Passaporte (se estrangeiro) <span class="text-red-700">*</span>'
    fieldsLabel['rg'] = 'RG <span class="text-red-700">*</span>'
    fieldsLabel['maritalStatus'] = ' Estado civil <span class="text-red-700">*</span>'
    fieldsLabel['email'] = 'E-mail <span class="text-red-700">*</span>'
    fieldsLabel['phone'] = 'Telefone <span class="text-red-700">*</span>'
    fieldsLabel['cellphone'] = 'Celular <span class="text-red-700">*</span>'
    fieldsLabel['zipcode'] = ' CEP <span class="text-red-700">*</span>'
    fieldsLabel['address'] = 'Logradouro <span class="text-red-700">*</span>'
    fieldsLabel['district'] = 'Bairro <span class="text-red-700">*</span>'
    fieldsLabel['city'] = 'Cidade <span class="text-red-700">*</span>'
    fieldsLabel['state'] = 'UF <span class="text-red-700">*</span>'
    fieldsLabel['profession'] = 'Profissão <span class="text-red-700">*</span>'
    fieldsLabel['curriculum'] = 'Currículo resumido <span class="text-red-700">*</span>'
    fieldsLabel['planAssociate'] = 'Plano Associado <span class="text-red-700">*</span>'
    fieldsLabel['subscriptionNumber'] = 'Inscrição <span class="text-red-700">*</span>'
    fieldsLabel['pensionSituation'] = 'Situação <span class="text-red-700">*</span>'
    fieldsLabel['subscriptionDate'] = 'Data inscrição <span class="text-red-700">*</span>'
    fieldsLabel['registration'] = 'Matrícula <span class="text-red-700">*</span>'
    fieldsLabel['birthdate'] = 'Data Nascimento <span class="text-red-700">*</span>'
    fieldsLabel['admissiondate'] = 'Data Admissão na Patrocinadora <span class="text-red-700">*</span>'

    fieldsPlaceholder['fullname'] = "Nome completo"
    fieldsPlaceholder['nickname'] = "Nome social"
    fieldsPlaceholder['cpf'] = "Apenas números"
    fieldsPlaceholder['rg'] = "Número do RG"
    fieldsPlaceholder['maritalStatus'] = "Estado civil"
    fieldsPlaceholder['email'] = "Endereço de e-mail"
    fieldsPlaceholder['phone'] = "Número telefônico"
    fieldsPlaceholder['cellphone'] = "com ddd"
    fieldsPlaceholder['zipcode'] = "CEP"
    fieldsPlaceholder['address'] = "Logradouro"
    fieldsPlaceholder['district'] = "Bairro"
    fieldsPlaceholder['city'] = "Cidade"
    fieldsPlaceholder['state'] = "Selecione um estado"
    fieldsPlaceholder['profession'] = "Profissão"
    fieldsPlaceholder['curriculum'] = 'Máximo de ' +
      this.CURRICULO_RESUMIDO_MAXLENGTH +
      ' caracteres...'
    fieldsPlaceholder['planAssociate'] = "Plano Associado"
    fieldsPlaceholder['subscriptionNumber'] = "Inscrição"
    fieldsPlaceholder['pensionSituation'] = "Situação"
    fieldsPlaceholder['subscriptionDate'] = "dd/mm/aaaa"
    fieldsPlaceholder['registration'] = "número de matrícula"
    fieldsPlaceholder['birthdate'] = "dd/mm/aaaa"
    fieldsPlaceholder['admissiondate'] = "dd/mm/aaaa"

    for (const fieldsKey in fields) {
      this.fieldsShow[fieldsKey] = fields[fieldsKey].show || false
      this.fieldsRequired[fieldsKey] = fields[fieldsKey].required || false
      this.fieldsLabel[fieldsKey] = fields[fieldsKey].label || fieldsLabel[fieldsKey]
      this.fieldsPlaceholder[fieldsKey] = fields[fieldsKey].placeholder || fieldsPlaceholder[fieldsKey]
      this.fieldsDisabled[fieldsKey] = fields[fieldsKey].disabled || false
    }

    this.subscription = subscriptionFromResponsible
    this.candidates.forEach((candidate) => {
      candidate.files.forEach((file, index) => {
        file.accept = filesRequired[index].accept;
        file.arquivo = filesRequired[index].arquivo;
        file.texto = filesRequired[index].texto;
        file.required = filesRequired[index].required || false;
      });
    });

    this.candidates.sort((a, b) => {
      return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
    });

  },
});
</script>
